import heavy_snow from './heavy_snow.svg';
import light_snow from './light_snow.svg';
import thunder from './thunder.svg';
import thunderstorm from './thunderstorm.svg';
import heavy_rain from './heavy_rain.svg';
import light_rain from './light_rain.svg';
import sunshine from './sunshine.svg';
import sunup from './sunup.svg';
import sun_cloud from './sun_cloud.svg';
import sun_cloud_rain from './sun_cloud_rain.svg';
import heavy_clouds from './heavy_clouds.svg';
import light_clouds from './light_clouds.svg';
import heavy_mist from './heavy_mist.svg';
import light_mist from './light_mist.svg';

import umbrella from './icon-umberella@2x.png';
import compass from './icon-compass@2x.png';
import wind from './icon-wind@2x.png';

export default {
    heavy_snow,
    light_snow,
    thunder,
    thunderstorm,
    heavy_rain,
    light_rain,
    sunshine,
    sunup,
    sun_cloud,
    sun_cloud_rain,
    heavy_clouds,
    light_clouds,
    heavy_mist,
    light_mist,

    umbrella,
    compass,
    wind,
};
