import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './images/logo-alt.png';
import banner from './images/banner.png';
import icons from './images/icons';
import AdSense from 'react-adsense';
import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyDDDWdhiYf420eauOpo6Y6M4JuxV5UijWE",
  authDomain: "moetikeenjasaan.firebaseapp.com",
  databaseURL: "https://moetikeenjasaan.firebaseio.com",
  projectId: "moetikeenjasaan",
  storageBucket: "moetikeenjasaan.appspot.com",
  messagingSenderId: "844101586627"
};
Firebase.initializeApp(config);

const db = Firebase.firestore();
const func = Firebase.functions();

const getAdvice = (temperature, rain = false) => {
  if (rain) {
    if (temperature < 9) {
      return 'Winterjas';
    } else if (temperature < 15) {
      return 'Dunne jas';
    } else if (temperature < 19) {
      return 'Regenjas';
    } else if (temperature < 26) {
      return 'T-shirt aan maar wel paraplu mee';
    }

    return 'Het is zo warm, een verfrissende bui kan geen kwaad';
  }

  if (temperature < 9) {
    return 'Winterjas';
  } else if (temperature < 15) {
    return 'Dikke trui';
  } else if (temperature < 19) {
    return 'Dunne trui of een t-shirt met lange mouwen';
  } else if (temperature < 26) {
    return 'T-shirt';
  }

  return 'Zo min mogelijk kleding';
};

const App = () => {
  const today = new Date(Date.now());

  const [requestLocation, setRequestLocation] = useState('de bilt');
  const [weather, setWeather] = useState({
    location: '',
    current: {
      temp: 0,
      feelslike: 0,
      icon: 'sun_cloud_rain',
      rain: false,
      wind: 0,
    },
    forecast: {
      temp: 0,
      feelslike: 0,
      icon: 'sun_cloud_rain',
      rain: false,
      wind: 0,
    },
    synced: 0
  });
  const getForecast = func.httpsCallable('getForecast');

  const getWeather = async () => {
    const querySnapshot = await db.collection('locations').get();
    let data = {};
    querySnapshot.forEach((doc) => {
      if (requestLocation && doc.id === requestLocation) {
        data = {
          location: doc.id,
          ...doc.data()
        };
      }
    });

    // if the data is outdated
    if (Math.round(Date.now() / 1000) - data.synced >= 43200) {
      const response = await getForecast({ location: requestLocation });
      return setWeather({
        location: requestLocation,
        ...response.data
      });
    }

    return setWeather(data);
  };

  const handleSubmit = async (event) => {
    if (event) {
        event.preventDefault();
    }
    // @todo:
    // - (future) try to get the data for the given location
    if (requestLocation) {
      const response = await getForecast({ location: requestLocation });
      setWeather({
        location: requestLocation,
        ...response.data
      });
    }
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <div className="site-content">
      <div className="site-header">
        <div className="container">
          <a href="index.html" className="branding">
            <img src={logo} alt="" className="logo" />
            <div className="logo-type">
              <h1 className="site-title">Moetikeenjasaan.nl</h1>
              <small className="site-description">Altijd een passend advies</small>
            </div>
          </a>

          <a href="https://shop.spreadshirt.nl/moetikeenjasaannl" target="_blank" rel="noopener noreferrer" className="branding" style={{ float: 'right', paddingTop: '20px', textAlign: 'right' }}>
            <div className="logo-type">
              <h1 className="site-title">Merchandise</h1>
              <small className="site-description">Koop nu onze merchandise!</small>
            </div>
          </a>
        </div>
      </div>

      <div className="hero" data-bg-image="./images/banner.png" style={{ backgroundImage: `url(${banner})` }}>
        <div className="container">
          <form onSubmit={handleSubmit} className="find-location">
            <input type="text" placeholder="Zoek op jouw locatie..." onChange={(event) => setRequestLocation(event.target.value)} />
            <input type="submit" value="Zoek" />
          </form>
        </div>

        <div className="container" style={{ textAlign: 'center' }}>
          <div className="row">
            <AdSense.Google
              className={'responsiveAd'}
              client='ca-pub-4660089377907455'
              slot='3362205460'
              style={{ display: 'inline-block' }}
            />
          </div>
        </div>
      </div>

      <div className="forecast-table">
        <div className="container">
          <div className="forecast-container">
            <div className="today forecast">
              <div className="forecast-header">
                <div className="day">Momentadvies</div>
                <div className="date" style={{ textTransform: 'uppercase' }}>{weather.location.name}</div>
              </div>
              <div className="forecast-content">
                <div className="location">
                  Advies:
                  <span className="degree" style={{ marginLeft: '10px' }}>
                    {getAdvice(weather.current.temp, weather.current.rain)}
                  </span>
                </div>

                <div className="degree">
                  <div className="num">{Math.round(weather.current.temp)}<sup>o</sup>C</div>
                  <div className="forecast-icon">
                    <img src={icons[weather.current.icon]} alt="" width="70" />
                  </div>
                </div>
                {weather.current.rain && (<span><img src={icons.umbrella} alt="" style={{ width: '21px' }} /></span>)}
                <span><img src={icons.wind} alt="" style={{ width: '21px' }} />{weather.current.wind}km/h</span>
              </div>
            </div>
            <div className="today forecast">
              <div className="forecast-header">
                {/* <div className="day">Voorspelling voor {today.toLocaleDateString('nl-NL', { weekday: 'long' })}</div> */}
                <div className="day">Voorspelling voor {weather.location.name}</div>
                <div className="date">{today.toLocaleDateString('nl-NL', { month: 'long', day: 'numeric' })}</div>
              </div>
              <div className="forecast-content">
                <div className="location">
                  Advies:
                  <span className="degree" style={{ marginLeft: '10px' }}>
                    {getAdvice(weather.forecast.temp, weather.forecast.rain)}
                  </span>
                </div>
                <div className="degree">
                  <div className="num">{Math.round(weather.forecast.temp)}<sup>o</sup>C</div>
                  <div className="forecast-icon">
                    <img src={icons[weather.forecast.icon]} alt="" width="70" />
                  </div>
                </div>
                {weather.forecast.rain && (<span><img src={icons.umbrella} alt="" style={{ width: '21px' }} /></span>)}
                <span><img src={icons.wind} alt="" style={{ width: '21px' }} />{weather.forecast.wind}km/h</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="forecast-table">
        <div className="container">
          <div className="forecast-container">
            <div className="today forecast">
              <div className="forecast-content">
                <div className="location">
                  <span className="degree">
                    Moetikeenjasaan.nl heeft een nieuw logo!
                  </span>
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  Je kunt vanaf nu merchandise met het moetikeenjasaan.nl logo aanschaffen in onze webshop! Klik <a href="https://shop.spreadshirt.nl/moetikeenjasaannl" target="_blank" rel="noopener noreferrer">hier</a> om te kijken wat we te bieden hebben.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="main-content">
        <div className="fullwidth">
          <div className="container">
            <div className="row">
              <AdSense.Google
                client='ca-pub-4660089377907455'
                slot='3362205460'
                style={{ display: 'block' }}
                format='auto'
                responsive='true'
              />
            </div>
          </div>
        </div>

        <div className="fullwidth-block">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="section-title">Over moetikeenjasaan.nl</h2>
                <ul className="arrow-feature">
                  <li>
                    <h3>Social media</h3>
                    <p>Volg ons op Twitter of like ons op Facebook om elke dag rond 07:00 het advies in je tijdlijn te ontvangen!</p>
                  </li>
                  <li>
                    <h3>Wat doet de website?</h3>
                    <p>Moetikeenjasaan.nl voorziet je altijd van een passend kledingadvies op basis van de actuele weersomstandigheden op je huidige locatie. Gebruik het invoerveld om je plaatsnaam in te typen of laat de browser je locatie bepalen door op het kompas icoon te klikken.</p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Link partners</h2>
                <ul className="arrow-list">
                  <li>Powered by <a href="https://darksky.net/poweredby/" target="_blank" rel="noopener noreferrer">Dark Sky</a></li>
                  <li><a href="https://www.jas.nl/winterjassen" target="_blank" rel="noopener noreferrer">Winterjassen.com</a></li>
                  <li><a href="https://www.24dagaanbieding.nl/" target="_blank" rel="noopener noreferrer">24dagaanbieding.nl</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              Ontwikkeld door <a href="https://www.shiftvision.nl/" target="_blank" rel="noopener noreferrer">ShiftVision</a>
              {/* <form action="#" className="subscribe-form">
                <input type="text" placeholder="Enter your email to subscribe..." />
                <input type="submit" value="Subscribe" />
              </form> */}
            </div>
            <div className="col-md-3 col-md-offset-1">
              <div className="social-links">
                <a href="https://www.facebook.com/moetikeenjasaan"><i className="fa fa-facebook"></i></a>&nbsp;
                <a href="https://twitter.com/moetikeenjasaan"><i className="fa fa-twitter"></i></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
